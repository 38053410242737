import { request } from './utils/request';
import promisify from './utils/promisify';
import { OAUTH_ENV_DOMAIN } from './utils/env';

/**
 * 微信 JSSDK 原生方法集合
 * @private
 */
const apis = [
  'startRecord',
  'stopRecord',
  'playVoice',
  'pauseVoice',
  'stopVoice',
  'uploadVoice',
  'downloadVoice',
  'chooseImage',
  'previewImage',
  'uploadImage',
  'downloadImage',
  'translateVoice',
  'getNetworkType',
  'openLocation',
  'getLocation',
  'scanQRCode',
  'chooseWXPay',
  'openProductSpecificView',
  'addCard',
  'chooseCard',
  'openCard',
  'hideOptionMenu',
  'showOptionMenu',
  'hideMenuItems',
  'showMenuItems',
  'hideAllNonBaseMenuItem',
  'showAllNonBaseMenuItem',
  'closeWindow',
  'openAddress',
];

/**
 * 微信 H5 JSSDK 封装类
 */
class WxH5 {
  /**
   * 当前是否应当执行 this.config
   * this.config 进行中和已结束时为 false，以阻止重复调用 wx.config
   *
   * @private
   * @type {boolean}
   */
  _shouldConfig = true

  /**
   * this.config 所返回的 Promise
   *
   * @private
   * @type {promise}
   */
  _configReadyPromise = new Promise((resolve, reject) => {
    this._resolveConfigReady = resolve;
    this._rejectConfigReady = reject;
  });

  /**
   * 创建 WxH5 实例
   */
  constructor(env, accountId) {
    // 浏览器中并且未使用 JSSDK，将所有实例方法置空
    if (window && !window.wx) {
      Object.getOwnPropertyNames(WxH5.prototype).forEach((propertyName) => {
        WxH5.prototype[propertyName] = () => { };
      });
      apis.map((api) => {
        this[api] = () => { };
      });
    } else {
      this._env = env;
      this._accountId = accountId;
      apis.map(async (api) => {
        await this._configReadyPromise;
        this[api] = (...args) => promisify(wx[api], ...args);
      });
    }
  }

  /**
   * 权限验证配置
   *
   * @param {object} options - 权限验证配置
   * @param {array} [options.jsApiList=[]] - 需要使用的 JS 接口列表
   * @param {boolean} [options.debug=false] - 是否开启调试模式
   * @param {string} [options.url] - 当前网页的 URL，不包含 # 及其后面部分。默认会从当前路径截取
   * @param {string} [options.channelId] - 指定签名所用的 channelId。默认会从 token 中获取
   * @param {string} [options.isNotDelegated] - 是否不使用服务商模式
   * @returns {promise}
   */
  async config(options = {}) {
    if (!this._shouldConfig) {
      return this._configReadyPromise;
    }

    const { jsApiList = [], debug = false, url, channelId, isNotDelegated = false } = options;
    this._shouldConfig = false;
    wx.ready(this._resolveConfigReady);
    wx.error((err) => { this._rejectConfigReady(err); });

    const params = { url: url || window.location.href.split('#')[0], channelId, isNotDelegated };
    const signatureConfig = await request.get('/v2/wechat/jssdk/signature', { params });
    this._appId = signatureConfig.appId;
    wx.config({ debug, jsApiList, ...signatureConfig });

    return this._configReadyPromise;
  }

  /**
   * 自定义 “分享给朋友”，“分享到 QQ”，“分享到朋友圈”及 “分享到 QQ 空间” 按钮的分享内容
   *
   * @param {object} options - 分享配置
   * @param {string} options.title - 分享标题
   * @param {string} options.desc - 分享描述
   * @param {string} options.link - 分享链接，指定哈希后的路径即可
   * @param {string} options.imgUrl - 分享图标
   * @param {boolean} [options.needOauth=true] - 是否需要 OAuth
   * @param {object} [options.oauthConfig] - OAuth 参数，如需 OAuth 必须指定。其所有字段都会以 'key=value' 的形式拼接到 OAuth URL 中
   * @param {string} [options.oauthConfig.scope] - 微信授权方式，如需 OAuth 必须指定
   * @param {boolean} [options.oauthConfig.isTest=false] - 是否是测试号，最终会被转换为 'is_test'
   * @returns {promise}
   */
  updateShareData(options) {
    return Promise.all([
      this.updateAppMessageShareData(options),
      this.updateTimelineShareData(options),
    ]);
  }

  /**
   * 自定义 “分享给朋友” 及 “分享到QQ” 按钮的分享内容
   *
   * @param {object} options - 分享配置
   * @param {string} options.title - 分享标题
   * @param {string} options.desc - 分享描述
   * @param {string} options.link - 分享链接，指定哈希后的路径即可
   * @param {string} options.imgUrl - 分享图标
   * @param {boolean} [options.needOauth=true] - 是否需要 OAuth
   * @param {object} [options.oauthConfig] - OAuth 参数，如需 OAuth 必须指定。其所有字段都会以 'key=value' 的形式拼接到 OAuth URL 中
   * @param {string} [options.oauthConfig.scope] - 微信授权方式，如需 OAuth 必须指定
   * @param {boolean} [options.oauthConfig.isTest=false] - 是否是测试号，最终会被转换为 'is_test'
   * @returns {promise}
   */
  async updateAppMessageShareData(options) {
    await this._configReadyPromise;
    const { title, desc, link, imgUrl, needOauth, oauthConfig } = options;
    const shareLinkOptions = { link, needOauth, oauthConfig };
    return new Promise((resolve) => {
      wx.updateAppMessageShareData({
        title,
        desc,
        link: this._generateShareLink(shareLinkOptions),
        imgUrl,
        success: resolve,
      });
    });
  }

  /**
   * 自定义 “分享到朋友圈” 及 “分享到QQ空间” 按钮的分享内容
   *
   * @param {object} options - 分享配置
   * @param {string} options.title - 分享标题
   * @param {string} options.link - 分享链接，指定哈希后的路径即可
   * @param {string} options.imgUrl - 分享图标
   * @param {boolean} [options.needOauth=true] - 是否需要 OAuth
   * @param {object} [options.oauthConfig] - OAuth 参数，如需 OAuth 必须指定。其所有字段都会以 'key=value' 的形式拼接到 OAuth URL 中
   * @param {string} [options.oauthConfig.scope] - 微信授权方式，如需 OAuth 必须指定
   * @param {boolean} [options.oauthConfig.isTest=false] - 是否是测试号，最终会被转换为 'is_test'
   * @returns {promise}
   */
  async updateTimelineShareData(options) {
    await this._configReadyPromise;
    const { title, link, imgUrl, needOauth, oauthConfig } = options;
    const shareLinkOptions = { link, needOauth, oauthConfig };
    return new Promise((resolve) => {
      wx.updateTimelineShareData({
        title,
        link: this._generateShareLink(shareLinkOptions),
        imgUrl,
        success: resolve,
      });
    });
  }

  /**
   * 自定义 “分享给朋友” 按钮的分享内容
   * `注意，`这个接口即将被微信废弃，但目前这是唯一能获取用户分享事件的 “分享给朋友” 接口，故依然提供其封装
   *
   * @param {object} options - 分享配置
   * @param {string} options.title - 分享标题
   * @param {string} options.desc - 分享描述
   * @param {string} options.link - 分享链接，指定哈希后的路径即可
   * @param {string} options.imgUrl - 分享图标
   * @param {string} [options.type='link'] - 分享类型，music、video 或 link
   * @param {string} [options.dataUrl] - 如果分享类型是 music 或 video，则要提供相关数据链接
   * @param {boolean} [options.needOauth=true] - 是否需要 OAuth
   * @param {object} [options.oauthConfig] - OAuth 参数，如需 OAuth 必须指定。其所有字段都会以 'key=value' 的形式拼接到 OAuth URL 中
   * @param {string} [options.oauthConfig.scope] - 微信授权方式，如需 OAuth 必须指定
   * @param {boolean} [options.oauthConfig.isTest=false] - 是否是测试号，最终会被转换为 'is_test'
   */
  onMenuShareAppMessage(options) {
    const { title, desc, link, imgUrl, type, dataURl, needOauth, oauthConfig, onSuccess } = options;
    const shareLinkOptions = { link, needOauth, oauthConfig };
    wx.onMenuShareAppMessage({
      title,
      desc,
      link: this._generateShareLink(shareLinkOptions),
      imgUrl,
      type,
      dataURl,
      success: onSuccess,
    });
  }

  /**
   * 自定义 “分享到朋友圈” 按钮的分享内容
   * `注意，`这个接口即将被微信废弃，但目前这是唯一能获取用户分享事件的 “分享到朋友圈” 接口，故依然提供其封装
   *
   * @param {object} options - 分享配置
   * @param {string} options.title - 分享标题
   * @param {string} options.link - 分享链接，指定哈希后的路径即可
   * @param {string} options.imgUrl - 分享图标
   * @param {boolean} [options.needOauth=true] - 是否需要 OAuth
   * @param {object} [options.oauthConfig] - OAuth 参数，如需 OAuth 必须指定。其所有字段都会以 'key=value' 的形式拼接到 OAuth URL 中
   * @param {string} [options.oauthConfig.scope] - 微信授权方式，如需 OAuth 必须指定
   * @param {boolean} [options.oauthConfig.isTest=false] - 是否是测试号，最终会被转换为 'is_test'
   */
  onMenuShareTimeline(options) {
    const { title, link, imgUrl, needOauth, oauthConfig, onSuccess } = options;
    const shareLinkOptions = { link, needOauth, oauthConfig };
    wx.onMenuShareTimeline({
      title,
      link: this._generateShareLink(shareLinkOptions),
      imgUrl,
      success: onSuccess,
    });
  }

  /**
   * 生成带 OAuth 的分享链接
   *
   * @private
   * @param {object} options - OAuth 参数
   * @param {string} options.link - 要分享的链接，指定哈希后面的部分即可
   * @param {boolean} options.needOauth - 是否需要 OAuth
   * @param {object} [options.oauthConfig] - OAuth 参数，如需 OAuth 必须指定。其所有字段都会以 'key=value' 的形式拼接到 OAuth URL 中
   * @param {string} [options.oauthConfig.scope] - 微信授权方式，如需 OAuth 必须指定
   * @param {boolean} [options.oauthConfig.isTest=false] - 是否是测试号，最终会被转换为 'is_test'
   * @returns {string} 带 OAuth 的分享链接
   */
  _generateShareLink(options = {}) {
    const { link, needOauth = true, oauthConfig } = options;
    const pageLink = `${window.location.href.split('#')[0]}#/${link}`;
    if (!needOauth) {
      return pageLink;
    }

    const { scope, isTest = false, ...othersConfig } = oauthConfig;
    const oAuthQuery = { scope, is_test: isTest, ...othersConfig };
    const queryString = Object.keys(oAuthQuery).reduce((res, key) => `${res}${key}=${oAuthQuery[key]}&`, '');
    const oAuthURL = `${OAUTH_ENV_DOMAIN[this._env]}/${this._accountId}/v2/wechat/oAuth`;
    return `${oAuthURL}?appid=${this._appId}&${queryString}redirect_url=${encodeURIComponent(pageLink)}`;
  }

  /**
   * 批量剔除无用 key
   *
   * @private
   * @param {object} obj - 原始对象
   * @param {array} uselessKeys - 要剔除的 keys
   * @returns {object} 剔除无用 key 后的对象
   */
  _omit(obj, uselessKeys) {
    return Object.keys(obj).reduce((res, key) => (uselessKeys.includes(key) ? res : { ...res, [key]: obj[key] }), {});
  }
}

export default WxH5;
