const isObject = value => Object.prototype.toString.call(value) === '[object Object]';

const isFunction = value => Object.prototype.toString.call(value) === '[object Function]';

const isWeapp = !window && !document && wx;

export {
  isObject,
  isFunction,
  isWeapp,
};
