const promisify = (func, obj = {}, ...args) => {
  return new Promise((resolve, reject) => {
    func.call(wx, {
      ...obj,
      success(res) {
        resolve(res);
        if (obj.success) {
        obj.success(res);
        }
      },
      fail(res) {
        reject(res);
        if (obj.fail) {
        obj.fail(res);
        }
      },
    }, ...args);
  });
};

export default promisify;
