import axios from 'axios';
import qs from 'qs';
import weappAdapter from './weappAxiosAdapter/adapter';
import { isWeapp } from './lang';
import { OAUTH_ENV_DOMAIN, BUSINESS_API_ENV_DOMAIN, CONSUMER_API_ENV_DOMAIN, CDN_ENV_DOMAIN } from './env';

/**
 * @type {axios}
 * @private
 */
let request = null;

// 为了实现外部代码添加 interceptors 不影响内部代码，需要添加 2 个 request
let outsideRequest = null;

// 确保 params 输出结果如 id[]=1&id[]=2&listCondition.page=1&listCondition.perPage=20
const paramsSerializer = params => qs.stringify(params, { arrayFormat: 'brackets', allowDots: true });

if (isWeapp) {
  const adapter = weappAdapter(axios);
  request = axios.create({
    adapter,
    paramsSerializer,
  });
  outsideRequest = axios.create({ adapter, paramsSerializer });
} else {
  request = axios.create({ paramsSerializer });
  outsideRequest = axios.create({ paramsSerializer });
}

/**
 * 环境配置
 *
 * @property {string} env - 环境
 * @property {string} accountId - 租户 Id
 * @property {string} oauthAPIDomain - OAuth 域名
 * @property {string} consumerAPIDomain - Consumer API 域名
 * @property {string} cdnDomain - CDN 域名
 */
const envConfig = {
  env: '',
  accountId: '',
  oauthAPIDomain: '',
  businessAPIDomain: '',
  consumerAPIDomain: '',
  cdnDomain: '',
};

const initRequest = (env, accountId) => {
  envConfig.env = env;
  envConfig.accountId = accountId;
  envConfig.consumerAPIDomain = CONSUMER_API_ENV_DOMAIN[env];
  envConfig.businessAPIDomain = BUSINESS_API_ENV_DOMAIN[env];
  envConfig.oauthAPIDomain = OAUTH_ENV_DOMAIN[env];
  envConfig.cdnDomain = CDN_ENV_DOMAIN[env];

  [request, outsideRequest].forEach((item) => {
    item.defaults.baseURL = envConfig.consumerAPIDomain;
    item.defaults.timeout = 3000;
    item.defaults.headers['X-Account-Id'] = accountId;
    item.defaults.headers['X-Requested-With'] = 'XMLHttpRequest';
    item.defaults.withCredentials = true;
  });

  request.interceptors.response.use((res) => {
    if (res.headers && res.headers['x-weapp-version']) {
      envConfig.weappVersion = res.headers['x-weapp-version'];
    }

    // eslint-disable-next-line no-console
    console.debug('request result', { request: res.request, status: res.status, response: res.data });
    return res.data;
  }, (error) => {
    // eslint-disable-next-line no-console
    console.error('request error', { error, request: error.request, response: error.response });
    return Promise.reject(error);
  });

  outsideRequest.interceptors.response.use((res) => {
    // eslint-disable-next-line no-console
    console.debug('request result', { request: res.request, status: res.status, response: res.data });
    return res;
  }, (error) => {
    // eslint-disable-next-line no-console
    console.error('request error', { error, request: error.request, response: error.response });
    return Promise.reject(error);
  });
};

const setAccessToken = (accessToken) => {
  [request, outsideRequest].forEach((item) => {
    item.defaults.headers['X-Access-Token'] = accessToken;
  });
};

export {
  initRequest,
  envConfig,
  request,
  outsideRequest,
  setAccessToken,
};
