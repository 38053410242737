import { Base64 } from 'js-base64';
import { envConfig } from './utils/request';

const QUERY_KEY = '?x-oss-process=image';
const NO_VALUE = 'x-oss-process-no-value';
const MP_QR_PREFIX = 'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=';

/**
 * OSS 图像处理类，链式处理 OSS 图像。详情参考[OSS 图片处理指南](https://help.aliyun.com/document_detail/44688.html?spm=a2c4g.11186623.6.1181.48c735a82TyC04)
 */
class OssImage {
  /**
   * 获得结果 url
   *
   * @type {string}
   */
  url = ''

  constructor({ url }) {
    this.url = url;
  }

  /**
   * 创建一个实例
   *
   * @param {string} url -  OSS 图片 url
   * @returns {OssImage}
   */
  static source(url = '') {
    return new OssImage({ url });
  }

  /**
   * 把微信头像 url 转码成群脉 CDN url
   *
   * @returns {OssImage}
   */
  transformWechatAvatar() {
    const base64Url = Base64.encodeURI(this.url);
    this.url = `${envConfig.cdnDomain}/avatar/wechat/${base64Url}`;
    return this;
  }

  /**
   *  把微信二维码 url 转码成群脉 CDN url
   *
   * @returns {OssImage}
   */
  transformWechatQRCode() {
    this.url = this.url.replace(MP_QR_PREFIX, `${envConfig.cdnDomain}/wechat/qrcode/`);
    return this;
  }

  /**
   * 在路径前添加 domain，如果不是以 / 开始的路径则没有任何影响
   *
   * @returns {OssImage}
   */
  resolveDomain() {
    if (/^\//.test(this.url)) {
      this.url = `${envConfig.cdnDomain}/${this.url}`;
    }
    return this;
  }

  /**
   * 移除 domain
   *
   * @returns {OssImage}
   */
  removeDomain() {
    this.url = this.url.replace(/^https?:\/\/[^/]+/, '');
    return this;
  }

  /**
   * @summary 将图片按照要求生成缩略图，或者进行特定的缩放。说明 图片处理支持的格式：jpg、png、bmp、gif、webp、tiff。
   *
   * @description
   * - 对于原图：
   *     1. 图片格式只能是：jpg、png、bmp、gif、webp、tiff。
   *     1. 文件大小不能超过 20MB。
   *     1. 使用图片旋转时图片的宽或者高不能超过 4096。
   *     1. 原图单边大小不能超过30,000。
   * - 对于缩略图：对缩略后的图片大小有限制，目标缩略图宽与高的乘积不能超过 4096x4096，且单边长度不能超过 4096x4。
   * - 关于长短边：“长边”是指原尺寸与目标尺寸的比值大的那条边，“短边”同理。例如，原图400x200，缩放为 800x100，由于 400/800=0.5，200/100=2，0.5 < 2，所以在这个缩放中 200 那条是长边，400 那条是短边。
   * - 当只指定宽度或者高度时，在等比缩放的情况下，都会默认进行单边的缩放。在固定宽高的模式下，会默认宽高一样的情况下进行缩略。
   * - 如果只指定宽度或者高度，原图按原图格式返回。如果想保存成其他格式，详细可以查看质量变换及格式转换。
   * - 调用 resize，默认是不允许放大。即如果请求的图片比原图大，那么返回的仍然是原图。如果想取到放大的图片，即增加参数调用 limit_0 （如：https://image-demo.oss-cn-hangzhou.aliyuncs.com/example.jpg?x-oss-process=image/resize,w_500,limit_0）
   *
   * @param {object} options
   * @param {'lfit' | 'mfit' | 'fill' | 'pad' | 'fixed'} options.m
   * - 指定缩略的模式 默认为 lfit。
   *     1. lfit：等比缩放，限制在指定w与h的矩形内的最大图片。
   *     1. mfit：等比缩放，延伸出指定w与h的矩形框外的最小图片。
   *     1. fill：固定宽高，将延伸出指定w与h的矩形框外的最小图片进行居中裁剪。
   *     1. pad：固定宽高，缩略填充。
   *     1. fixed：固定宽高，强制缩略。
   * @param {number} options.w
   * - 指定目标缩略图的宽度。
   * - 取值范围 1-4096
   * @param {number} options.h
   * - 指定目标缩略图的高度。
   * - 取值范围 1-4096
   * @param {number} options.l
   * - 指定目标缩略图的最长边。
   * - 取值范围 1-4096
   * @param {number} options.s
   * - 指定目标缩略图的最短边。
   * - 取值范围 1-4096
   * @param {number} options.limit
   * - 指定当目标缩略图大于原图时是否处理。值是 1 表示不处理；值是 0 表示处理。
   * - 取值范围 0/1, 默认是 1
   * @param {string} options.color
   * - 当缩放模式选择为 pad（缩略填充）时，可以选择填充的颜色(默认是白色)参数的填写方式：采用 16 进制颜色码表示，如 00FF00（绿色）。
   * - 取值范围 [000000-FFFFFF]
   * @param {number} options.p
   * - 按比例缩放,倍数百分比。小于 100，即是缩小，大于 100 即是放大。
   * - 取值范围 1-1000
   *
   * @returns {OssImage}
   */
  resize(options) {
    return this._operate('resize', options);
  }

  /**
   * @summary 您可以把图片保存成圆形，并指定圆形的大小 。
   *
   * @description
   * - 如果图片的最终格式是 png、webp、 bmp 等支持透明通道的图片，那么图片非圆形区域的地方将会以透明填充。如果图片的最终格式是 jpg，那么非圆形区域是以白色进行填充。推荐保存成 png 格式。
   * - 如果指定半径大于原图最大内切圆的半径，则圆的大小仍然是图片的最大内切圆。
   *
   * @param {object} options
   * @param {number} options.r
   * - 从图片取出的圆形区域的半径
   * - 半径 r 不能超过原图的最小边的一半。如果超过，则圆的大小仍然是原圆的最大内切圆。
   *
   * @returns {OssImage}
   */
  circle(options) {
    return this._operate('circle', options);
  }

  /**
   * @summary 您可以指定裁剪的起始点以及裁剪的宽高来决定图片裁剪的区域。
   *
   * @description
   * - 如果指定的起始横纵坐标大于原图，将会返回错误：BadRequest, 错误内容是：Advance cut’s position is out of image.
   * - 如果从起点开始指定的宽度和高度超过了原图，将会直接裁剪到原图结尾。
   *
   * @description
   * - 如果指定的起始横纵坐标大于原图，将会返回错误：BadRequest, 错误内容是：Advance cut’s position is out of image.
   * - 如果从起点开始指定的宽度和高度超过了原图，将会直接裁剪到原图结尾。
   *
   * @param {object} options
   * @param {number} options.w
   * - 指定裁剪宽度
   * - [0-图片宽度]
   * @param {number} options.h
   * - 指定裁剪高度
   * - [0-图片高度]
   * @param {number} options.x
   * - 指定裁剪起点横坐标（默认左上角为原点）
   * - [0-图片边界]
   * @param {number} options.y
   * - 指定裁剪起点纵坐标（默认左上角为原点）
   * - [0-图片边界]
   * @param {'nw' | 'north' | 'ne' | 'west' | 'center' | 'east' | 'sw' | 'south' | 'se'} options.g
   * - 设置裁剪的原点位置，由九宫格的格式，一共有九个地方可以设置，每个位置位于每个九宫格的左上角
   *
   * @returns {OssImage}
   */
  crop(options) {
    return this._operate('crop', options);
  }

  /**
   * @summary 将图片分成 x，y 轴，按指定长度 (length) 切割，指定索引 (index)，取出指定的区域。
   *
   * @description 如果指定的索引大于切割后范围，将返回原图。
   *
   * @param {object} options
   * @param {number} options.x
   * - 进行水平切割，每块图片的长度。x 参数与 y 参数只能任选其一。
   * - [1,图片宽度]
   * @param {number} options.y
   * - 进行垂直切割，每块图片的长度。x 参数与 y 参数只能任选其一。
   * - [1,图片高度]
   * @param {number} options.i
   * - 选择切割后第几个块。（0表示第一块）
   * - [0,最大块数)。如果超出最大块数，返回原图。
   *
   * @returns {OssImage}
   */
  indexCrop(options) {
    return this._operate('index-crop', options);
  }

  /**
   * @summary 您可以把图片保存成圆角矩形，并指定圆角的大小 。
   *
  * @description
   * - 如果图片的最终格式是 png、webp、bmp 等支持透明通道的图片，那么图片非圆形区域的地方将会以透明填充。如果图片的最终格式是 jpg， 那么非圆形区域是以白色进行填充 。推荐保存成 png 格式。
   * - 如果指定半径大于原图最大内切圆的半径，则圆角的大小仍然是图片的最大内切圆。
   *
   * @param {object} options
   * @param {object} options.r
   * - 将图片切出圆角，指定圆角的半径。
   * - [1, 4096] 生成的最大圆角的半径不能超过原图的最小边的一半。
   *
   * @returns {OssImage}
   */
  roundedCorners(options) {
    return this._operate('rounded-corners', options);
  }

  /**
   * @summary 某些手机拍摄出来的照片可能带有旋转参数（存放在照片exif信息里面）。可以设置是否对这些图片进行旋转，默认进行自动旋转。
   *
   * @description
   * - 进行自适应方向旋转，要求原图的宽度和高度必须小于 4096。
   * - 如果原图没有旋转参数，加上auto-orient参数不会对图片进行旋转。
   *
   * @param {0 | 1} orient 进行自动旋转
   * - 0：表示按原图默认方向，不进行自动旋转。
   * - 1：先进行图片旋转，然后再进行缩略。
   *
   * @returns {OssImage}
   */
  autoOrient(orient) {
    return this._operate('auto-orient', { [orient]: NO_VALUE });
  }

  /**
   * @summary 可以将图片按顺时针旋转。
   *
   * @description
   * - 旋转图片可能会导致图片的尺寸变大。
   * - 旋转对图片的尺寸有限制，图片的宽或者高不能超过 4096。
   *
   * @param {number} rotate
   * - 图片按顺时针旋转的角度
   * - [0, 360]默认值为 0，表示不旋转。
   *
   * @returns {OssImage}
   */
  rotate(rotate) {
    return this._operate('rotate', { [rotate]: NO_VALUE });
  }

  /**
   * @summary 可以对图片进行模糊操作。
   *
   * @param {object} options
   * @param {number} options.r
   * - 模糊半径
   * - [1,50]r 越大图片越模糊。
   * @param {number} options.s
   * - 正态分布的标准差
   * - [1,50]s 越大图片越模糊。
   *
   * @returns {OssImage}
   */
  blur(options) {
    return this._operate('blur', options);
  }

  /**
   * @summary 您可以对处理后的图片进行亮度调节。
   *
   * @param {number} bright
   * - 亮度调整。0 表示原图亮度，小于 0 表示低于原图亮度，大于 0 表示高于原图亮度。
   * - [-100, 100]
   *
   * @returns {OssImage}
   */
  bright(bright) {
    return this._operate('bright', { [bright]: NO_VALUE });
  }

  /**
   * @summary 您可以对处理后的图片进行对比度调节。
   *
   * @param {number} contrast
   * - 对比度调整。0 表示原图对比度，小于 0 表示低于原图对比度，大于 0 表示高于原图对比度。
   * - [-100, 100]
   *
   * @returns {OssImage}
   */
  contrast(contrast) {
    return this._operate('contrast', { [contrast]: NO_VALUE });
  }

  /**
   * @summary 您可以对处理后的图片进行锐化，使图片变得清晰。
   *
   * @param {number} sharpen
   * - 表示进行锐化处理。取值为锐化参数，参数越大，越清晰。
   * - [50, 399] 为达到较优效果，推荐取值为 100。
   *
   * @returns {OssImage}
   */
  sharpen(sharpen) {
    return this._operate('sharpen', { [sharpen]: NO_VALUE });
  }

  /**
   * @summary 您可以将图片转换成对应格式，包括 jpg、png、bmp、webp、gif、tiff。 不填格式，则默认按原图格式返回。
   *
   * @description
   * - 对于普通缩略请求， 建议 format 参数放到处理参数串最后，例如：image/resize,w_100/format,jpg。
   * - 对于缩略+水印的请求，建议 format 参数跟缩略参数放在一起，例如：image/reisze,w_100/format,jpg/watermark,...。
   * - 保存成 jpg 格式时，默认是保存成标准型的 jpg (Baseline JPEG)， 如果想指定是渐进式 JPEG (Progressive JPEG), 可以指定参数 interlace，详见渐进显示。
   *
   * @param {string} format
   * 1. jpg: 将原图保存成 jpg 格式，如果原图是 png、webp、bmp 存在透明通道，默认会把透明填充成白色。
   * 1. png: 将原图保存成 png 格式。
   * 1. webp: 将原图保存成 webp 格式。
   * 1. bmp: 将原图保存成 bmp 格式。
   * 1. gif: 将 gif 格式保存成 gif 格式，非 gif 格式是按原图格式保存。
   * 1. tiff: 将原图保存成 tiff 格式。
   *
   * @returns {OssImage}
   */
  format(format) {
    return this._operate('format', { [format]: NO_VALUE });
  }


  /**
   * @summary
   * - 图片格式为 jpg 时有两种呈现方式：
   *    1. 自上而下的扫描式
   *    1. 先模糊后逐渐清晰（在网络环境比较差时明显）
   * - 默认保存为第一种，如果要指定先模糊后清晰的呈现方式，请使用渐进显示参数。
   *
   * @description 此参数只有当效果图是 jpg 格式时才有意义 。
   *
   * @param {number} interlace
   * 1. 1 表示保存成渐进显示的 jpg 格式。
   * 1. 0 表示保存成普通的 jpg 格式。
   *
   * @returns {OssImage}
   */
  interlace(interlace) {
    return this._operate('interlace', { [interlace]: NO_VALUE });
  }

  /**
   * @summary 如果图片保存成 jpg 或 webp, 可以支持质量变换。
   *
   * @description 如果不填 Q 或者 q 这两个参数，有可能会导致图片占用空间变大。如明确想得到一个质量固定的图片，请采用 Q 参数。
   *
   * @param {object} options
   * @param {number} options.q
   * - 决定图片的相对质量，对原图按照 q% 进行质量压缩。如果原图质量是 100%，使用 90q 会得到质量为 90％ 的图片；如果原图质量是 80%，使用 90q 会得到质量72%的图片。
   * - 只能在原图是 jpg 格式的图片上使用，才有相对压缩的概念。如果原图为 webp，那么相对质量就相当于绝对质量。
   * - 1-100
   * @param {number} options.Q
   * - 决定图片的绝对质量，把原图质量压到Q%，如果原图质量小于指定数字，则不压缩。如果原图质量是100%，使用“90Q”会得到质量90％的图片；如果原图质量是95%，使用“90Q”还会得到质量90%的图片；如果原图质量是80%，使用“90Q”不会压缩，返回质量80%的原图。
   * -
   * - 只能在保存格式为jpg/webp效果上使用，其他格式无效果。 如果同时指定了q和Q，按Q来处理。
   * - 1-100
   *
   * @returns {OssImage}
   */
  quality(options) {
    return this._operate('quality', options);
  }

  /**
   * @summary 您可以在图片上设置另外一张图片或者文字做为水印。
   *
   * @description
   * - 图片上的水印图只能使用当前存储空间内的图片，网络图片需上传至当前存储空间内方可使用。
   * - 水印图目前仅支持png、jpg和webp格式。
   *
   * ### 基础参数
   * - 水平边距、垂直边距、中线垂直偏移不仅可以调节水印在图片中的位置，而且当图片存在多重水印时，也可以调节两张水印在图中的布局。
   * - 用到的URL安全的Base64位编码可以参考文档下方的解释。
   * - 区域数值以及每个区域对应的基准点如下图。
   *
   * ### 图片水印参数
   * - 水印图片预处理
   * - 用户在打水印时，可以对水印图片进行预处理，支持的预处理操作有：图片缩放，图片裁剪（不支持内切圆)，图片旋转（具体内容请直接查看文档相关章节）。在“resize”操作下还额外支持一个参数：P（大写P），表示水印图片按主图的比例进行处理，取值范围为[1, 100]，表示百分比。
   * - 说明 对panda.png按30%缩放。 那么水印文件是：panda.png?x-oss-process=image/resize,P_30 （经过URL安全base64编码后是：cGFuZGEucG5nP3gtb3NzLXByb2Nlc3M9aW1hZ2UvcmVzaXplLFBfMzA)。
   *
   * @param {object} options
   *
   * @param {number} options.t 基础参数
   * - 参数意义：透明度, 如果是图片水印，就是让图片变得透明，如果是文字水印，就是让水印变透明。
   * - 默认值：100， 表示 100%（不透明）
   * - 取值范围: [0-100]
   * - 可选参数
   *
   * @param {'nw' | 'north' | 'ne' | 'west' | 'center' | 'east' | 'sw' | 'south' | 'se'} options.g
   * - 参数意义：位置，水印打在图的位置，详情参考下方区域数值对应图。
   * - 取值范围：[nw,north,ne,west,center,east,sw,south,se]
   * - 可选参数
   * @param {number} options.x 基础参数
   * - 参数意义：水平边距, 就是距离图片边缘的水平距离， 这个参数只有当水印位置是左上，左中，左下， 右上，右中，右下才有意义。
   * - 默认值：10
   * - 取值范围：[0 – 4096]
   * - 单位：像素（px）
   * - 可选参数
   * @param {number} options.y 基础参数
   * - 参数意义：垂直边距, 就是距离图片边缘的垂直距离， 这个参数只有当水印位置是左上，中上， 右上，左下，中下，右下才有意义
   * - 默认值：10
   * - 取值范围：[0 – 4096]
   * - 单位：像素(px)
   * - 可选参数
   * @param {number} options.voffset 基础参数
   * - 参数意义： 中线垂直偏移，当水印位置在左中，中部，右中时，可以指定水印位置根据中线往上或者往下偏移
   * - 默认值：0
   * - 取值范围：[-1000, 1000]
   * - 单位：像素(px)
   * - 可选参数
   *
   * @param {string} options.image 图片水印参数
   * - 参数意义： 水印图片为当前的Bucket下Object
   * - 必选参数
   *
   * @param {string} options.text 文字水印参数
   * - 参数意义：表示文字水印的文字内容
   * - 必选参数
   * @param {'wqy-zenhei' | 'wqy-microhei' | 'fangzhengshusong' | 'fangzhengkaiti' | 'fangzhengheiti' | 'fangzhengfangsong' | 'droidsansfallback'} options.type 文字水印参数
   * - 参数意义：表示文字水印的文字类型
   *     1. wqy-zenhei 文泉驿正黑
   *     1. wqy-microhei 文泉微米黑
   *     1. fangzhengshusong 方正书宋
   *     1. fangzhengkaiti 方正楷体
   *     1. fangzhengheiti 方正黑体
   *     1. fangzhengfangsong 方正仿宋
   *     1. droidsansfallback DroidSansFallback
   * - 默认值：wqy-zenhei
   * - 可选参数
   * @param {string} options.color 文字水印参数
   * - 参数意义：文字水印的文字的颜色
   * - 参数的构成必须是：六个十六进制数，如：000000表示黑色。 000000每两位构成RGB颜色， FFFFFF表示的是白色
   * - 默认值：000000黑色
   * - 可选参数
   * @param {number} options.size 文字水印参数
   * - 参数意义：文字水印的文字大小(px)
   * - 取值范围：(0，1000]
   * - 默认值：40
   * - 可选参数
   * @param {number} options.shadow 文字水印参数
   * - 参数意义：文字水印的阴影透明度
   * - 取值范围：[0,100]
   * - 可选参数
   * @param {number} options.rotate 文字水印参数
   * - 参数意义：文字顺时针旋转角度
   * - 取值范围：[0,360]
   * - 可选参数
   * @param {number} options.fill 文字水印参数
   * - 参数意义：进行水印铺满的效果
   * - 取值范围：[0,1]，
   *     1. 1表示铺满，
   *     1. 0表示效果无效
   * - 可选参数
   *
   * @param {number} options.order 文图混合
   * - 参数意义： 文字，图片水印前后顺序
   * - 取值范围：[0, 1]
   *    1. order = 0 图片在前(默认值)
   *    1. order = 1 文字在前
   * - 可选参数
   * @param {0 | 1 | 2} options.align 文图混合
   * - 参数意义：文字、图片对齐方式
   * - 取值范围：[0, 1, 2]
   *    1. align = 0 上对齐(默认值)
   *    1. align = 1 中对齐
   *    1. align = 2 下对齐
   * - 可选参数
   * @param {number} options.interval 文图混合
   * - 参数意义：文字和图片间的间距
   * - 取值范围: [0, 1000]
   * - 可选参数
   *
   * @returns {OssImage}
   */
  watermark(options) {
    if (options.image) {
      options.image = options.image.replace(/^https?:\/\/[^/]+/, '').replace(/^\//, '');
    }

    for (const field of ['image', 'text', 'type']) {
      if (options[field]) {
        options[field] = Base64.encodeURI(options[field]);
      }
    }

    return this._operate('watermark', options);
  }

  /**
   * @private
   * @param {string} type
   * @param {object} options
   *
   * @returns {OssImage}
   */
  _operate(type, options) {
    if (!this.url.includes(QUERY_KEY)) {
      this.url += QUERY_KEY;
    }

    this.url += `/${type},`;
    this.url += Object.keys(options)
      .filter(key => options[key] !== undefined)
      .map((key) => {
        if (options[key] === NO_VALUE) {
          return key;
        }

        return `${key}_${options[key]}`;
      })
      .join(',');

    return this;
  }
}

export default OssImage;
