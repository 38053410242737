import qs from 'qs';
import { initRequest, outsideRequest, envConfig } from './utils/request';
import { CONSUMER_API_ENV_DOMAIN } from './utils/env';
import Member from './Member';
import MemberEvent from './MemberEvent';
import Puppeteer from './Puppeteer';
import Log from './Log';
import Weapp from './Weapp';
import OssImage from './OssImage';
import Captcha from './Captcha';
import WxH5 from './WxH5';
import WxWeapp from './WxWeapp';
import { WeappAuthScope } from './Enums';

/**
 * MaiJS 类
 */
class Mai {
  /**
   * @type {Member}
   */
  member = null;

  /**
   * @type {Weapp}
   */
  weapp = null;

  /**
   * @type {MemberEvent}
   */
  memberEvent = null;

  /**
   * @type {Log}
   */
  log = null;

  /**
   * axiox 对象，使用方式参考 {@link https://github.com/axios/axios}
   * @type {AxiosInstance}
   */
  request = null;

  /**
   * @type {Puppeteer}
   */
  puppeteer = null;

  /**
   * @type {typeof WeappAuthScope}
   */
  WeappAuthScope = WeappAuthScope;

  /**
   * @type {typeof OssImage}
   */
  OssImage = OssImage;

  /**
   * @type {typeof Captcha}
   */
  Captcha = Captcha;

  /**
   * @type {WxH5}
   */
  wxH5 = null;

  /**
   * @type {WxWeapp}
   */
  wxWeapp = WxWeapp;

  /**
   * @type {typeof envConfig}
   */
  envConfig = envConfig;

  /**
   * @type {typeof qs}
   */
  qs = qs;

  /**
   * 根据 env 和 accountId 创建 MaiJS 实例
   *
   * @param {string} env - 环境变量，可选值 scrm-dev，scrm-staging，scrm-production，usp-staging，usp-production，masterkong-production
   * @param {string} accountId - 群脉账号 id
   */
  constructor(env, accountId) {
    Mai.validate(env, accountId);
    initRequest(env, accountId);
    this.memberEvent = new MemberEvent();
    this.member = new Member();
    this.log = new Log();
    this.weapp = new Weapp();
    this.request = outsideRequest;
    this.puppeteer = new Puppeteer();
    this.wxH5 = new WxH5(env, accountId);

    // eslint-disable-next-line no-console
    console.info(`maijs@${process.env.SDK_VERSION} initialized`);
  }

  /**
   * 验证构造器参数类型
   *
   * @private
   * @param {string} env - 环境变量
   * @param {string} accountId - 群脉账号 id
   */
  static validate(env, accountId) {
    if (!env) {
      throw new Error('env is required');
    }
    if (!CONSUMER_API_ENV_DOMAIN[env]) {
      const envStr = Object.keys(CONSUMER_API_ENV_DOMAIN).join(', ');
      throw new Error(`The value of env must be one of ${envStr}`);
    }
    if (!accountId) {
      throw new Error('accountId is required');
    }
    if (accountId.length !== 24) {
      throw new Error('accountId is invalid');
    }
  }
}

export default Mai;
