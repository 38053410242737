const CONSUMER_API_ENV_DOMAIN = {
  local: 'http://ajax.wm.com:9093',
  'scrm-dev': 'https://dev-consumer-api.quncrm.com',
  'scrm-staging': 'https://staging-consumer-api.quncrm.com',
  'scrm-production': 'https://consumer-api.quncrm.com',
  'usp-staging': 'https://staging-consumer-api.usocialplus.com',
  'usp-production': 'https://consumer-api.usocialplus.com',
  'masterkong-production': 'https://consumer-api.masterkong.com.cn',
};

const OAUTH_ENV_DOMAIN = {
  local: 'http://oauth.wm.com',
  'scrm-dev': 'https://dev-oauth.quncrm.com',
  'scrm-staging': 'https://staging-oauth.quncrm.com',
  'scrm-production': 'https://oauth.quncrm.com',
  'usp-staging': 'https://staging-oauth.usocialplus.com',
  'usp-production': 'https://oauth.usocialplus.com',
  'masterkong-production': 'https://oauth.masterkong.com.cn',
};

const BUSINESS_API_ENV_DOMAIN = {
  local: 'http://ajax.wm.com:9091',
  'scrm-dev': 'https://dev-business-api.quncrm.com',
  'scrm-staging': 'https://staging-business-api.quncrm.com',
  'scrm-production': 'https://business-api.quncrm.com',
  'usp-staging': 'https://staging-business-api.usocialplus.com',
  'usp-production': 'https://business-api.usocialplus.com',
  'masterkong-production': 'https://business-api.masterkong.com.cn',
};

const CDN_ENV_DOMAIN = {
  local: 'https://dev-statics.maiscrm.com',
  'scrm-dev': 'https://dev-statics.maiscrm.com',
  'scrm-staging': 'https://staging-statics.maiscrm.com',
  'scrm-production': 'https://statics.maiscrm.com',
  'usp-staging': 'https://staging-statics.usocialplus.com',
  'usp-production': 'https://statics.usocialplus.com',
  'masterkong-production': 'https://masterkong-production-statics.quncrm.com',
};

export {
  CONSUMER_API_ENV_DOMAIN,
  BUSINESS_API_ENV_DOMAIN,
  OAUTH_ENV_DOMAIN,
  CDN_ENV_DOMAIN,
};
