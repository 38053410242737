import { request } from './utils/request';
import { isWeapp } from './utils/lang';

/**
 * 人机验证类
 */
class Captcha {
  /**
   * 人机验证场景码
   *
   * @static
   * @memberof Captcha
   * @type {object}
   */
  static Scene = {
    ACCOUNT: 'account',
    SMS: 'sms',
    CAMPAIGN: 'campaign',
    OTHER: 'other',
  };

  /**
   * 人机验证结果返回值
   *
   * @static
   * @memberof Captcha
   * @type {object}
   */
  static Ret = {
    SUCCESS: 0,
    USER_CLOSED: 2,
  };

  /**
   * 是否已经引入人机验证 js 脚本，仅在 h5 页面有效
   *
   * @static
   * @memberof Captcha
   * @type {boolean}
   */
  static hasLoadedScript = false;

  /**
   * 人机验证配置信息
   *
   * @private
   * @type {object}
   */
  _config = null;

  /**
   * 人机验证的场景码
   *
   * @private
   * @type {string}
   */
  _scene = Captcha.Scene.SMS;

  /**
   * 创建人机验证实例
   *
   * @param {string} [scene] - 人机验证使用场景。默认值 sms，可选值 account（账号场景）、sms（短信场景）、campaign（活动场景）、other（其他场景）
   */
  constructor(scene) {
    if (!isWeapp && !Captcha.hasLoadedScript) {
      const script = document.createElement('script');
      script.src = 'https://ssl.captcha.qq.com/TCaptcha.js';
      document.body.appendChild(script);
      Captcha.hasLoadedScript = true;
    }
    if (scene) {
      const scenes = Object.values(Captcha.Scene);
      if (!scenes.includes(scene)) {
        throw new Error(`The value of scene must be one of ${scenes.join(', ')}`);
      }
      this._scene = scene;
    }
    this._fetchConfig().then((data) => {
      this._config = data;
    });
  }

  /**
   * 获取人机验证配置信息
   *
   * @private
   * @returns {promise}
   */
  _fetchConfig() {
    return request.get('/v2/captchaConfig', {
      params: {
        scene: this._scene,
      },
    });
  }

  /**
   * 发送短信验证码
   *
   * @param {string} phone - 需要发送短信验证码的手机号
   * @returns {promise}
   */
  async sendSmsVerificationCode(phone) {
    if (!phone) {
      throw new Error('phone is required');
    }
    const { ticket, randstr } = await this.show();
    return request.post('/v2/sendSmsVerificationCode', {
      phone,
      captcha: {
        provider: this._config.provider,
        randStr: randstr,
        ticket,
      },
    });
  }

  /**
   * 显示人机验证弹窗
   *
   * @returns {promise}
   */
  show() {
    return new Promise((resolve, reject) => {
      const txCaptchaAppId = 'wx5a3a7366fd07e119';
      const { appId } = this._config;
      if (isWeapp) {
        wx.navigateToMiniProgram({
          appId: txCaptchaAppId,
          path: '/pages/captcha/index',
          extraData: { appId },
        });
        if (wx.onAppShow) {
          wx.onAppShow((options) => {
            if (options.scene === 1038 && options.referrerInfo.appId === txCaptchaAppId) {
              const result = options.referrerInfo.extraData;
              // 点击验证码微信小程序右上角关闭微信小程序，此时 result 为 undefined
              // 点击验证码的返回按钮，此时 result.ret 为 1
              if (result && result.ret === Captcha.Ret.SUCCESS) {
                resolve(result);
              } else {
                const err = { ret: Captcha.Ret.USER_CLOSED };
                reject(err);
              }
            }
          });
        } else {
          reject(new Error('微信小程序版本过低，最低基础库版本不得低于 2.1.2'));
        }
      } else {
        new window.TencentCaptcha(appId, (result) => {
          if (result.ret === Captcha.Ret.SUCCESS) {
            resolve(result);
          } else {
            reject(result);
          }
        }).show();
      }
    });
  }
}

export default Captcha;
