import localStorage from './utils/localStorage';
import { OAUTH_ENV_DOMAIN } from './utils/env';
import { uuid } from './utils/string';
import { isWeapp, isObject, isFunction } from './utils/lang';
import { request, envConfig, setAccessToken } from './utils/request';
import wxWeapp from './WxWeapp';
import { WeappAuthScope } from './Enums';

/**
 * 客户类
 */
class Member {
  /**
   * 当前客户信息
   *
   * @private
   * @type {object}
   */
  _member = null;

  /**
   * 当前渠道 ID
   *
   * @private
   * @type {string}
   */
  _channelId = '';

  /**
   * 当前渠道 openId
   *
   * @private
   * @type {string}
   */
  _openId = '';

  /**
   * 当前渠道 unionId
   *
   * @private
   * @type {string}
   */
  _unionId = '';

  /**
   * access token，优先级低于 cookie 中的 accessToken
   *
   * @private
   * @type {string}
   */
  _accessToken = '';

  /**
   * 创建客户实例(单例)
   */
  constructor() {
    const { instance } = Member;
    if (instance) {
      return instance;
    }
    this._clientIdKey = 'mai_client_id';
    this.clientId = this.getClientId();
    Member.instance = this;
    return this;
  }

  /**
   * OAuth 且把当前客户与该 client 的匿名事件绑定
   *
   * @param {object} options - 选项
   * @param {string} [options.appId] - 微信小程序 app id，仅支持微信小程序。如果最低版本大于 2.2.2 可以不传
   * @param {WeappAuthScope} [options.scope] - 微信小程序授权类型，仅支持微信小程序
   * @param {string} [options.code] - 微信小程序[用户登录凭证](https://developers.weixin.qq.com/miniprogram/dev/api/open-api/login/wx.login.html)，仅支持微信小程序。在微信小程序插件中该参数必填
   * @param {string} [options.iv] - 微信小程序[加密算法的初始向量](https://developers.weixin.qq.com/miniprogram/dev/api/open-api/user-info/wx.getUserInfo.html)，仅支持微信小程序。在微信小程序插件中 options.code = userinfo 时该参数必填
   * @param {string} [options.encryptedData] - 微信小程序[包括敏感数据在内的完整用户信息的加密数据](https://developers.weixin.qq.com/miniprogram/dev/api/open-api/user-info/wx.getUserInfo.html)，仅支持微信小程序。在微信小程序插件中 options.code = userinfo 时该参数必填
   * @param {string} [options.channelId] - 指定 channelId，指定后可以使用和 member 相关的方法，仅支持 H5
   * @param {string} [options.accessToken] - 适用于微信小程序嵌入 H5，已经在微信小程序内做过 OAuth 但不想在 H5 内再做一遍的场景，可将微信小程序的 accessToken 传给 H5
   * @param {function} [options.callback] - 绑定客户成功或失败后会执行的回调，适用于多页应用在绑定后再进行页面跳转
   *
   * @example
   * // 微信小程序
   * const mai = new Mai(env, accountId);
   * mai.member.signin({scope: mai.WeappAuthScope.BASE, appId});
   *
   * @example
   * // H5
   * const mai = new Mai(env, accountId);
   * mai.member.signin();
   */
  async signin(options = {}) {
    if (isWeapp) {
      const data = {
        scope: options.scope,
        code: options.code,
        watermark: { appid: options.appId },
      };

      if (!data.code) {
        const { code } = await wxWeapp.login();
        data.code = code;
      }

      if (wx.getAccountInfoSync) {
        const { miniProgram } = wx.getAccountInfoSync();
        data.watermark.appid = miniProgram.appId;
      } else if (!options.appId) {
        throw new Error('appId is required');
      }

      if (data.scope === WeappAuthScope.USER_INFO) {
        let { iv, encryptedData } = options;
        if (!iv || !encryptedData) {
          ({ iv, encryptedData } = await wxWeapp.getUserInfo());
        }

        data.iv = iv;
        data.encrypted_data = encryptedData;
      }

      const baseURL = `${OAUTH_ENV_DOMAIN[envConfig.env]}/${envConfig.accountId}`;
      const auth = await request.post('/v2/weapp/oauth', data, { baseURL });
      setAccessToken(auth.accessToken);
      this._accessToken = auth.accessToken;
      this._member = auth.member;
      this._channelId = auth.channelId;
      this._openId = auth.openId;
      this._unionId = auth.unionId;
      if (auth.member) {
        request.post('/v2/memberEventLogs/bindAnonymousToMember', {
          clientId: this.clientId,
        });
      }
    } else {
      if (options.accessToken) {
        setAccessToken(options.accessToken);
        this._accessToken = options.accessToken;
      }

      if (options.channelId) {
        this._channelId = options.channelId;
        try {
          this._member = await request.get('/v2/member');
        } catch (error) {
          if (error.response.status !== 401) {
            throw error;
          }
        }
      }

      try {
        await request.post('/v2/memberEventLogs/bindAnonymousToMember', {
          clientId: this.clientId,
        });
      } finally {
        // 多页应用，如果在调了 signin() 后，页面跳转，浏览器则会 cancel 该请求
        // 支持调用方传 callback，确保 signin 成功或者失败后再做后续操作
        if (isObject(options) && isFunction(options.callback)) {
          options.callback();
        }
      }
    }
  }

  /**
   * 给当前客户打标签，需要授权 {@link Member#signin}
   *
   * @param {Array<string>} tags - 新标签数组，不能为空
   */
  addTags(tags = []) {
    if (!tags.length) {
      throw new Error('tags is required');
    }

    return request.put(
      '/v2/member/tag/add',
      { tags },
    );
  }

  /**
   * 创建活动传播链，需要授权 {@link Member#signin}。比如 A 邀请了 B 参与某项活动，那么活动裂变场景的集成流程：
   * - 用户 A 报名参与活动，生成专属海报后，调用此接口，inviterOpenId 为空。
   * - 用户 B 访问了 A 海报中的链接，调用此接口，inviterOpenId 为 A 的 openId，视为 A 邀请 B 参与活动。
   * - 如果之前 B 已经访问了用户 C 的海报，仍然视为 C 邀请了 B（一个用户只能被邀请一次）。
   *
   * @param {string} affiliateProgramId - 营销活动 ID
   * @param {string} [inviterOpenId] 邀请者 open ID
   */
  async trackAffiliation(affiliateProgramId, inviterOpenId) {
    if (!affiliateProgramId) {
      throw new Error('affiliateProgramId is required');
    }

    await request.post(
      `/v2/marketing/affiliatePrograms/${affiliateProgramId}/track`,
      { inviterOpenId },
    );
  }

  /**
   * 获取客户详情
   *
   * @param {Array<'Card'|'Properties'>} [extraFields=[]] - 控制返回客户扩展字段
   * - Card：返回值中会带有该客户的会员卡信息
   * - Properties：返回值中会带有该客户的属性设置
   * @returns {Promise<object>}
   */
  async getDetail(extraFields = []) {
    const member = await request.get('/v2/member', { params: { extraFields } });
    for (const property of member.properties || []) {
      for (const propertyKey of Object.keys(property)) {
        if (/^value/.test(propertyKey)) {
          property.value = property[propertyKey].value;
          delete property[propertyKey];
          break;
        }
      }
    }

    return member;
  }

  /**
   * 搜索客户属性
   *
   * @param {object} options - 选项
   * @param {boolean} [options.isDefault=null] - 是否是默认客户属性
   * @param {boolean} [options.isVisible=null] - 是否是可见客户属性
   * @param {Array<string>} [options.ids=[]] - 客户属性 ID
   * @param {Array<string>} [options.names=[]] - 属性名称
   * @param {Array<string>} [options.propertyIds=[]] - 自定义属性 ID
   * @param {object} [options.listCondition={}] - 分页选项
   * @param {number} [options.listCondition.page=1] - 页码
   * @param {number} [options.listCondition.perPage=20] - 每页数据数目
   * @param {Array<string>} [options.listCondition.orderBy=[]] - 排序字段。按字段排序，例如 ["createdAt", "-createdAt"]
   * @returns {Promise<Array<object>>}
   */
  async searchPropertyInfo(options = {}) {
    const params = options;
    ['isDefault', 'isVisible', 'isVisibleInFilter'].forEach((fieldKey) => {
      if (options[fieldKey] !== undefined) {
        params[`${fieldKey}`] = { value: options[fieldKey] };
      }
    });

    const { items } = await request.get('/v2/member/properties', { params });
    return items;
  }

  /**
   * 设置客户属性
   *
   * @param {Object[]} properties 客户属性列表
   * @param {string} properties[].propertyId 客户属性 ID
   * @param {string} properties[].type 客户属性类型
   * @param {any} properties[].value 客户属性值
   * @returns {Promise<object>}
   */
  async setProperties(properties = []) {
    const items = [];
    for (const property of properties) {
      const { propertyId, type, value } = property;
      const item = { propertyId };
      const propertyTypeMap = {
        location: 'valueNumberArray',
        images: 'valueArray',
        address: 'valueArray',
        checkbox: 'valueArray',
        date: 'valueDate',
        datetime: 'valueDate',
        number: 'valueNumber',
        currency: 'valueNumber',
        bool: 'valueBool',
      };
      const fieldKey = propertyTypeMap[type] || 'valueString';
      item[fieldKey] = { value };
      items.push(item);
    }

    const result = await request.put('/v2/member', { properties: items });
    return result;
  }

  /**
   * 获取客户 clientId 以追踪匿名客户行为
   *
   * @private
   * @returns {string} 随机生成的客户端 UUID
   */
  getClientId() {
    let clientId = localStorage.getItem(this._clientIdKey);
    if (!clientId) {
      clientId = uuid();
      localStorage.setItem(this._clientIdKey, clientId);
    }
    return clientId;
  }

  /**
   * 获取 channel id，需要授权 {@link Member#signin}
   * - 支持微信小程序
   * - H5 返回 signin 时选项中 channelId
   *
   * @returns {string}
   */
  getChannelId() {
    return this._channelId;
  }

  /**
   * 获取 access token，需要授权 {@link Member#signin}
   * - 支持微信小程序
   * - H5 返回 signin 时选项中 accessToken
   *
   * @private
   * @returns {string}
   */
  getAccessToken() {
    return this._accessToken;
  }

  /**
   * 获取客户 id，需要授权 {@link Member#signin}
   * - 支持微信小程序
   * - H5 需要 signin 时选项包含 channelId
   *
   * @returns {string}
   */
  getMemberId() {
    if (this._member && this._member.id) {
      return this._member.id;
    }
    return '';
  }

  /**
   * 获取客户是否激活，需要授权 {@link Member#signin}
   * - 支持微信小程序
   * - H5 需要 signin 时选项包含 channelId
   *
   * @returns {boolean}
   */
  isActivated() {
    return !!(this._member && this._member.isActivated);
  }

  /**
   * 获取客户当前渠道信息，需要授权 {@link Member#signin}
   * - 支持微信小程序
   * - H5 需要 signin 时选项包含 channelId
   *
   * @returns {object}
   */
  getSocial() {
    if (!this._member) {
      return null;
    }

    if (this._member.originFrom.channel === this._channelId) {
      return this._member.originFrom;
    }

    return this._member.socials.find(item => item.channel === this._channelId);
  }

  /**
   * 获取客户当前渠道 nickname，需要授权 {@link Member#signin}
   * - 支持微信小程序
   * - H5 需要 signin 时选项包含 channelId
   *
   * @returns {string}
   */
  getNickname() {
    const social = this.getSocial();
    if (social && social.nickname) {
      return social.nickname;
    }
    return '';
  }

  /**
   * 获取客户当前渠道 openId，需要授权 {@link Member#signin}
   * - 支持微信小程序
   * - H5 需要 signin 时选项包含 channelId
   *
   * @returns {string}
   */
  getOpenId() {
    if (this._openId) {
      return this._openId;
    }

    const social = this.getSocial();
    if (social && social.openId) {
      return social.openId;
    }
    return '';
  }

  /**
   * 获取客户当前渠道 unionId，需要授权 {@link Member#signin}
   * - 支持微信小程序
   * - H5 需要 signin 时选项包含 channelId
   *
   * @returns {string}
   */
  getUnionId() {
    if (this._unionId) {
      return this._unionId;
    }

    const social = this.getSocial();
    if (social && social.unionId) {
      return social.unionId;
    }
    return '';
  }

  /**
   * 获取客户当前渠道是否授权，需要授权 {@link Member#signin}
   * - 支持微信小程序
   * - H5 需要 signin 时选项包含 channelId
   *
   * @returns {boolean}
   */
  isSocialAuthorized() {
    const social = this.getSocial();
    return !!(social && social.authorized);
  }

  /**
   * 更新当前客户信息
   *
   * @private
   * @param {Object} params 需要更新的字段集合
   */
  updateAs = (params) => {
    this._member = { ...this._member, ...params };
  }
}

export default Member;
